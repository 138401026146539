import React from 'react';

import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import Layout from '../components/layout';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalSmallTitle from '../components/horizontalSmallTitle';
import HorizontalButtonsContainer from '../components/horizontalButtonsContainer';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';

import overview from '../images/logo.png';

import ucsdLogo from '../images/ucsdLogo.png';
import hpaLogo from '../images/hpaLogo.png';
import stanfordLogo from '../images/stanfordLogo.png';

import styles from './pageStyles/index.module.css';

const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const IndexPage = () => (
  <Layout>
    <SEO title='MuSIC Project' />
    <TopPanel>
      <HorizontalSmallTitle title='MuSIC Project' subtitle='Multi-Scale Integrated Cell Maps' />
      <VerticalButtonsContainer>
        <img src={overview} style={{ width: '20vw', minWidth: '10em' }} />
      </VerticalButtonsContainer>
      <VerticalButtonsContainer>
          <ButtonLink
            text='About MuSIC'
            link='about/'
            tooltip={false}
            newTab={false}
          />
          
                  <ButtonLink
            text='Download Data'
            link='data/'
            tooltip={false}
           // tooltipPosition='bottom'
            //tooltipText='The team'
            newTab={false}
          />
                    <ButtonLink
            text='Browse Tools'
            link='tools'
            tooltip={false}
            newTab={false}
            //tooltipPosition='top'
           // tooltipText={'The github repository containing the MuSIC pipeline code'}
          />
          {/* <ButtonLink
            text='U2OS MuSIC Map (5254 proteins)'
            link='http://hiview.ucsd.edu/e289703e-2cb3-11ee-828b-005056aecf54?type=test&server=http://test.ndexbio.org'
            tooltip={false}
            //tooltipPosition='top'
            //tooltipText={'Browse the U2OS MuSIC Map on HiView' }
          /> */}
        </VerticalButtonsContainer>
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
      <map_title>
      <LinkOut link='u2os-cellmap/'>U2OS MuSIC Map</LinkOut>
      </map_title>
      <br></br>
      <map_title>
        (5,147 proteins)
      </map_title>

      </div>      
        <div className={styles.logoGroup}>
       <map_title>
        <LinkOut link='https://nrnb.org/music'>HEK293 MuSIC Map</LinkOut>
      </map_title>
      <br></br>
      <map_title>
      Map (661 proteins)
      </map_title>
      
      </div>
      </div>
    </TopPanel>
    <Panel>
      
      <h2>Multi-Scale Integrated Cell (MuSIC) Maps chart eukaryotic cell architecture by systematically
        integrating proteomic data modalities across scales. </h2>
        <h2>
        <counter> 24,008 </counter> immunofluorescent images for<counter>{'\t'} 11,053 </counter> proteins
      </h2>
      <h2>
      <counter> 95,100 </counter>  biophysical interactions for<counter>{'\t'} 10,254 </counter> proteins
      </h2>
      <h2>
      <counter> 339 </counter>  MuSIC subcellular components
      </h2>
      <div>
      </div>
      {/* <div className={styles.logosContainer}>
        <img src={overview} style={{ width: '25vw', minWidth: '10em' }} />
      </div> */}
    </Panel>
      </Layout>
);

export default IndexPage;
